var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"f-active",style:({ backgroundColor: _vm.fissionInfo.activityBackColor })},[(_vm.ChannelModule.channel === 'plat_h5' && _vm.isShowBack)?_c('van-nav-bar',{ref:"flashSale",attrs:{"fixed":"","z-index":"1000","title":_vm.fissionInfo.activityName,"border":false,"placeholder":true},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('van-image',{attrs:{"fit":_vm.imageFit,"width":"0.6rem","height":"0.6rem","src":require('@/assets/images/LimitActivities/l2.png')},on:{"click":function($event){$event.preventDefault();return _vm.onClickLeft.apply(null, arguments)}}})]},proxy:true}],null,false,1582264873)}):_vm._e(),_c('van-row',{staticClass:"r-v-img"},[_c('van-image',{attrs:{"fit":_vm.imageFit,"width":"100%","height":"4.7rem","src":_vm.fissionInfo.headImageUrl}}),(_vm.fissionInfo.activityDescription)?_c('div',{staticClass:"r-v-hint",on:{"click":function($event){$event.preventDefault();return _vm.activityRules.apply(null, arguments)}}},[_vm._v(" 活动规则 ")]):_vm._e()],1),_c('div',{staticClass:"f-a-main"},[_c('TopBtn',{on:{"goHistory":_vm.goHistory,"goMyPrize":_vm.goMyPrize}}),_c('div',{staticClass:"m-center"},[_c('div',{staticStyle:{"padding":"0 0.2rem"}},[_c('TimeNotice',{attrs:{"time-state":_vm.dealTime(
              _vm.fissionInfo.activityStartTime,
              _vm.fissionInfo.activityEndTime
            ).type,"timer":_vm.dealTime(
              _vm.fissionInfo.activityStartTime,
              _vm.fissionInfo.activityEndTime
            ).timer}}),(_vm.fissionInfo.prizeList)?_c('FissionPrize',{attrs:{"prize-list":_vm.fissionInfo.prizeList}}):_vm._e(),(_vm.fissionInfo.guideImageUrl)?_c('van-image',{staticStyle:{"display":"block","margin-bottom":"0.2rem"},attrs:{"fit":_vm.imageFit,"width":"100%","radius":"0.16rem","height":"4.19rem","src":_vm.fissionInfo.guideImageUrl}}):_vm._e()],1),_c('FriendsList',{attrs:{"open-sort":_vm.openSort,"activity-state":_vm.dealTime(_vm.fissionInfo.activityStartTime, _vm.fissionInfo.activityEndTime)
            .type,"people-list":_vm.peopleList},on:{"ViewList":_vm.ViewList,"inviteFriends":_vm.inviteFriends}})],1)],1),_c('van-dialog',{attrs:{"confirm-button-text":"我知道了","show-confirm-button":_vm.dialogType === 1},on:{"confirm":function($event){_vm.showPrivacy = false}},model:{value:(_vm.showPrivacy),callback:function ($$v) {_vm.showPrivacy=$$v},expression:"showPrivacy"}},[(_vm.dialogType === 1)?_c('div',{staticClass:"f-rules"},[_c('div',{staticClass:"r-title"},[_vm._v("活动规则")]),_c('div',{staticClass:"r-main",domProps:{"innerHTML":_vm._s(_vm.fissionInfo.activityDescription)}})]):_vm._e(),(_vm.dialogType === 2)?[_c('MyPrize',{attrs:{"prize-list":_vm.prizeList},on:{"close":function($event){_vm.showPrivacy = false},"goView":_vm.goView}})]:_vm._e(),(_vm.dialogType === 3)?[_c('HelpLeaderboard',{attrs:{"help-list":_vm.helpList},on:{"close":function($event){_vm.showPrivacy = false}}})]:_vm._e()],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }