

























import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Image, CountDown } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
    [CountDown.name]: CountDown,
  },
})
export default class TimeNotice extends Mixins(Mixin) {
  @Prop({
    type: Number,
    default: 2,
  })
  public timeState!: number; //1,活动已结束 2. 离活动开始还有多久， 3。活动进行中离结束还有多久
  @Prop({
    type: Number,
    default: 0,
  })
  public timer!: number;
  dealTime(num: number): string {
    let time = "";
    if (num < 10) {
      time = "0" + num.toString();
    } else {
      time = num.toString();
    }
    return time;
  }

  finish(): void {
    (this.$parent as any).getDataInfo();
  }
}
