
































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Image, Icon } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
    [Icon.name]: Icon,
  },
})
export default class FriendsList extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public peopleList!: cms.ActivityUserHelpDto[];

  @Prop({
    type: Number,
    default: 0,
  })
  public openSort!: number; //是否展示排序

  @Prop({
    type: Number,
    default: 1,
  })
  public activityState!: number; //1,活动已结束 2. 离活动开始还有多久， 3。活动进行中离结束还有多久

  isShow = false;
  mounted(): void {
    this.isShow = false;
  }
  /*
   * 查看所以好友
   */
  viewAllFFriends(): void {
    if (this.isShow === true) {
      return;
    }
    this.isShow = true;
  }
  /*
   * 查看列表
   */
  ViewList(): void {
    this.$emit("ViewList");
  }
  /*
   * 邀请好友助力
   */
  inviteFriends(): void {
    if (this.activityState === 3) {
      this.$emit("inviteFriends");
    }
  }
  btnName(state: number): string {
    let name = "邀请好友助力";
    if (state === 2) {
      name = "活动即将开始";
    } else if (state === 1) {
      name = "活动结束";
    }
    return name;
  }
}
