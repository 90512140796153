import { render, staticRenderFns } from "./FriendsList.vue?vue&type=template&id=45c9a115&scoped=true&"
import script from "./FriendsList.vue?vue&type=script&lang=ts&"
export * from "./FriendsList.vue?vue&type=script&lang=ts&"
import style0 from "./FriendsList.vue?vue&type=style&index=0&id=45c9a115&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45c9a115",
  null
  
)

export default component.exports