









































import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Image } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import PrizeFooter from "./PrizeFooter.vue";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
    PrizeFooter,
  },
})
export default class PrizeOne extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public prizeList!: Array<cms.ActivityPrizeDto>;
}
