

























































































































import { Component, Mixins } from "vue-property-decorator";
import { NavBar, Swipe, SwipeItem, Image, Row, Dialog } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import TopBtn from "@/views/LimitActivities/components/Fission/TopBtn.vue";
import TimeNotice from "@/views/LimitActivities/components/Fission/TimeNotice.vue";
import FissionPrize from "@/views/LimitActivities/components/Fission/FissionPrize.vue";
import FriendsList from "@/views/LimitActivities/components/Fission/FriendsList.vue";
import MyPrize from "@/views/LimitActivities/components/Fission/MyPrize.vue";
import HelpLeaderboard from "@/views/LimitActivities/components/Fission/HelpLeaderboard.vue";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
    [Row.name]: Row,
    [Dialog.Component.name]: Dialog.Component,
    TopBtn,
    TimeNotice,
    FissionPrize,
    FriendsList,
    MyPrize,
    HelpLeaderboard,
  },
})
export default class FissionActivityDemo extends Mixins(Mixin) {
  showPrivacy = false;
  dialogType = 1;
  fissionInfo: cms.ActivityInfoDto = { menuType: "" };
  prizeList: cms.ActivityWinningRecordDto[] = [];
  id = "";
  helpList: cms.ActivityInvitationInfoDto[] = [];
  peopleList: cms.ActivityUserHelpDto[] = [];
  openSort = 0;
  isShowBack = true;

  updateData(): void {
    this.getAllMethods();
    document.addEventListener("visibilitychange", this.refreshPage);
  }
  refreshPage(): void {
    if (document.visibilityState == "visible") {
      let ua = this.getBrowserEnvironment();
      if (ua.ios) {
        this.getAllMethods();
      }
    }
  }
  beforeDestroy(): void {
    document.removeEventListener("visibilitychange", this.refreshPage);
  }
  getAllMethods(): void {
    this.isShowBack = this.$route.query.isTab ? false : true; //是否是首页的tab,是不展示返回按钮
    this.id = this.$route.query.id ? String(this.$route.query.id) : "";
    if (this.id) {
      this.getDataInfo();
    }
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }

  /*
   * 获取活动信息
   */
  getDataInfo(): void {
    this.$api.cmsApi.lucky.findActivityInfoById(
      this.id,
      ({ data }) => {
        this.fissionInfo = data;
        this.getRule();
        this.getHelpPeople();
        window.document.title = data.activityName || "";
        if (
          this.ChannelModule.channel === "plat_ios" ||
          this.ChannelModule.channel === "plat_android"
        ) {
          this.jsBridge("isShowShare", "1");
          window.startShare = this.shareDate;
        }
      },
      (error) => {
        if (error.includes("@_@")) {
          this.$toast(error.split("@_@")[1]);
        } else {
          error && this.$toast(error);
        }
      }
    );
  }

  shareDate(): void {
    let url = `${location.origin}${location.pathname}#/fission-activity?id=${this.id}`;
    let title = this.fissionInfo.activityName || "";
    let shareDescribe = this.fissionInfo.shareDescription || "";
    let shareImg = this.fissionInfo.shareImageUrl || "";
    this.jsBridge(
      "share",
      `title%&@/${title}%&@,desc%&@/${shareDescribe}%&@,picUrl%&@/${shareImg}%&@,url%&@/${url}`
    );
  }

  /*
   * 获取活动规则
   */
  getRule(): void {
    this.$api.cmsApi.activityInfo.findActivityInfoById(
      this.id,
      ({ data }) => {
        let res = data.activityRuleDto;
        this.openSort = res ? (res.openSort ? res.openSort : 0) : 0;
      },
      (error) => {
        if (error.includes("@_@")) {
          this.$toast(error.split("@_@")[1]);
        } else {
          error && this.$toast(error);
        }
      }
    );
  }

  /*
   * 去查看或兑换
   */
  goView(item: cms.ActivityWinningRecordDto): void {
    if (item.prizeStatus === 0) {
      //去兑换
      let data = {
        commodityInfoId: item.trophyId,
        num: item.number || 1,
        winningId: item.id,
        type: item.winningType,
      };
      this.$router.push(`/commodity-exchange?data=${JSON.stringify(data)}`);
    } else {
      //去查看
      this.getViewPrizes(item);
    }
    this.showPrivacy = false;
  }

  /*
   * 处理时间
   */

  dealTime(
    startTime?: string,
    endTime?: string
  ): { type: number; timer: number } {
    let timeData = {
      type: 1,
      timer: 0,
    };
    if (startTime && endTime) {
      let nowTime = new Date().getTime();
      if (nowTime < Number(startTime)) {
        timeData.type = 2;
        timeData.timer = Number(startTime) - nowTime;
      } else if (nowTime > Number(startTime) && nowTime < Number(endTime)) {
        timeData.type = 3;
        timeData.timer = Number(endTime) - nowTime;
      } else {
        timeData.type = 1;
        timeData.timer = 0;
      }
    }
    return timeData;
  }

  /*
   * 活动规则
   */
  activityRules(): void {
    this.showPrivacy = true;
    this.dialogType = 1;
  }

  /*
   * 去历史活动
   */
  goHistory(): void {
    this.$router.push(`/fission-activity/fission-history`);
  }

  /*
   * 去我的奖品
   */
  goMyPrize(): void {
    this.showPrivacy = true;
    this.dialogType = 2;
    this.getMyPrizeInfo();
  }
  /*
   * 我的奖品列表
   */
  getMyPrizeInfo(): void {
    this.$api.cmsApi.lucky.findMyActivityPrize(this.id, ({ data }) => {
      this.prizeList = data;
    });
  }

  /*
   * 助力用户信息展示
   */
  getHelpPeople(): void {
    let data = {
      activeInvitationCode: this.fissionInfo.activityInvitationCode
        ? this.fissionInfo.activityInvitationCode
        : "",
      activityId: this.id,
    };
    this.$api.cmsApi.activityInfo.findActivityHelpInfo(data, ({ data }) => {
      this.peopleList = data;
    });
  }

  /*
   * 查看列表
   */
  ViewList(): void {
    this.showPrivacy = true;
    this.dialogType = 3;
    this.getHelpList();
  }
  /*
   * 获取前十
   */
  getHelpList(): void {
    let data = {
      activeInvitationCode: this.fissionInfo.activityInvitationCode
        ? this.fissionInfo.activityInvitationCode
        : "",
      activityId: this.id,
    };
    this.$api.cmsApi.activityInfo.findActivityHelpTopTen(data, ({ data }) => {
      this.helpList = data;
    });
  }
  /*
   * 邀请好友助力
   */
  inviteFriends(): void {
    let nowTime = new Date().getTime();
    let startTime = this.fissionInfo.activityStartTime;
    let endTime = this.fissionInfo.activityEndTime;
    if (nowTime < Number(startTime)) {
      this.$toast("活动未开始");
      return;
    } else if (nowTime > Number(endTime)) {
      this.$toast("活动已结束");
      return;
    } else if (this.fissionInfo.activityState === "0") {
      this.$toast("活动未上架");
      return;
    } else {
      let childUrl = `/fission-activity/help-results?id=${this.id}&activeInvitationCode=${this.fissionInfo.activityInvitationCode}`;
      if (
        this.ChannelModule.channel === "plat_ios" ||
        this.ChannelModule.channel === "plat_android"
      ) {
        let url = `${location.origin}${location.pathname}#${childUrl}`;
        let title = this.fissionInfo.activityName || "";
        let shareDescribe = this.fissionInfo.shareDescription || "";
        let shareImg = this.fissionInfo.shareImageUrl || "";
        this.jsBridge(
          "share",
          `title%&@/${title}%&@,desc%&@/${shareDescribe}%&@,picUrl%&@/${shareImg}%&@,url%&@/${url}`
        );
      } else {
        this.$router.push(childUrl);
      }
    }
  }
}
