





















































import { Component, Mixins } from "vue-property-decorator";
import { NavBar, List, Image, Row, Dialog } from "vant";
import { Mixin } from "@/core/mixins/mixin";
import HisroryNull from "@/views/LimitActivities/components/Fission/HisroryNull.vue";
import HisroryItem from "@/views/LimitActivities/components/Fission/HisroryItem.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [List.name]: List,
    [Image.name]: Image,
    [Row.name]: Row,
    [Dialog.Component.name]: Dialog.Component,
    HisroryNull,
    HisroryItem,
  },
})
export default class FissionHistory extends Mixins(Mixin) {
  showPrivacy = false;
  loading = false;
  finished = false;
  pages = 1; //总页数
  page = 0;
  size = 20;
  historyList: cms.ActivityHistoryDto[] = [];

  mounted(): void {
    this.getData();
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }

  /*
   *  获取信息
   */
  getData(): void {
    this.page++;
    let data = {
      page: this.page,
      size: this.size,
    };
    this.$api.cmsApi.activityInfo.findActivityHistoryInfo(data, ({ data }) => {
      this.loading = false;
      this.pages = data.pages ? data.pages : 1;
      let list = data.records || [];
      this.historyList = this.historyList.concat(list);
    });
  }

  onLoad(): void {
    this.loading = true;
    this.getData();

    // 数据全部加载完成
    if (this.page >= Number(this.pages)) {
      this.finished = true;
      this.loading = false;
    }
  }

  /*
   *  去活动详情
   */
  goHistoryDetail(item: cms.ActivityHistoryDto): void {
    let nowTime = new Date().getTime();
    if (nowTime > new Date(item.activityEndTime!).getTime()) {
      this.showPrivacy = true;
    } else {
      if (item.activityType === "5") {
        this.$router.push(`/fission-activity?id=${item.activityId}`);
      }
    }
  }
}
