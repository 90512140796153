
















import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Image } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
  },
})
export default class PrizeFooter extends Mixins(Mixin) {
  @Prop({
    type: Number,
    default: 0,
  })
  public surplusPrizeNumber!: number;
}
