



















import { Component, Mixins, Prop } from "vue-property-decorator";
import { Row, Image } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [Row.name]: Row,
    [Image.name]: Image,
  },
})
export default class ResultFooter extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "还没有活动",
  })
  public title!: string;
  @Prop({
    type: Number,
    default: 2,
  })
  public type!: number;
  /*
   * 去使用
   */
  goUse(): void {
    this.$emit("goUse");
  }
  /*
   * 成为“锦鲤”
   */
  becomeKoi(): void {
    this.$emit("becomeKoi");
  }
}
